import {AuthLayout} from '@app/components/Layouts/pages/AuthLayout';
import type {DzrGetStaticProps, DzrPage} from '@app/types/next';
import {COBRANDED_APPS} from '@app/types/next';
import type {GetStaticPaths} from 'next';
import {useAuthController} from '@app/hooks/controllers/useAuthController';
import React from 'react';
import {getTemplate} from '@app/modules/server/template';
import {getStaticTranslations} from '@app/modules/translations';
import {AuthForm} from '@app/components/AuthForms';
import {useTranslation} from '@app/hooks/useTranslation';
import {config} from '@app/modules/config';
import {AccountSection} from '@app/components/Layouts/elements';

const LoginPage: DzrPage = () => {
	const {authCallback, isLoading, authConfig} = useAuthController();
	const t = useTranslation();
	return (
		<AuthLayout isLoading={isLoading} title={t('formal.title_login')}>
			<AccountSection>
				<AuthForm
					authConfig={authConfig.data}
					intent="login"
					onSuccess={authCallback}
				/>
			</AccountSection>
		</AuthLayout>
	);
};

export const getStaticProps: DzrGetStaticProps = async ({
	locale = '',
	params,
}) => {
	const translations = await getStaticTranslations(locale, {
		appName: params?.appName,
		additionnalNamespaces: ['error', 'revokePassword', 'login'],
	});
	const {appName, colorMode, template} = getTemplate(params?.appName);
	return {
		props: {
			seo: {
				title: ['login.seotitle'],
				description: [
					'login.seodescription',
					{
						count: config.get('totalTracks'),
						sprintf: [config.get('totalTracks')],
					},
				],
			},
			appName,
			colorMode,
			template,
			...translations,
		},
	};
};

export const getStaticPaths: GetStaticPaths = ({locales}) => {
	const paths: {locale: string; params: {appName: string}}[] = [];
	locales?.forEach((locale) => {
		COBRANDED_APPS.forEach((cobrandedApp) => {
			paths.push({
				params: {appName: cobrandedApp.toLowerCase()},
				locale,
			});
		});
	});
	return {
		paths,
		fallback: false,
	};
};

export default LoginPage;
